import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class YearlyBalancedBatteryWithTargetWidget extends BaseWidget<{
  plantId: string;
  title: string;
}> {
  static readonly id = 'yearly-balanced-battery-with-target';
  static readonly publicFriendly = false;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    return new YearlyBalancedBatteryWithTargetWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    super(
      injector,
      YearlyBalancedBatteryWithTargetWidget.id,
      YearlyBalancedBatteryWithTargetWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols,
      data
    );
    this.resolveComponent(async () => {
      const module = await import('./yearly-balanced-battery-with-target.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string; title: string; currency?: string }): void {
    this.setData({
      plantId: data.plantId,
      title: data.title
    });
  }
}
