import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class YearlyAutarkyWidget extends BaseWidget<{ plantId: string; title: string }> {
  static readonly id = 'yearly-autarky';
  static readonly publicFriendly = false;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    return new YearlyAutarkyWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    super(injector, YearlyAutarkyWidget.id, YearlyAutarkyWidget.publicFriendly, metaLinkId, x, y, rows, cols, data);
    this.resolveComponent(async () => {
      const module = await import('../yearly/yearly-autarky.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string; title: string }): void {
    this.setData({ plantId: data.plantId, title: data.title });
  }
}
