import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class PlantPictureWidget extends BaseWidget<{ plantId: string }> {
  static id = 'plant-picture';
  static readonly publicFriendly = true;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string; currency: string }
  ) {
    return new PlantPictureWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string }
  ) {
    super(injector, PlantPictureWidget.id, PlantPictureWidget.publicFriendly, metaLinkId, x, y, rows, cols, data);
    this.resolveComponent(async () => {
      const module = await import('./plant-picture.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string }): void {
    this.setData({ plantId: data.plantId });
  }
}
