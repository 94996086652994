import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class MonthLifetimeInverterRgmProductionComparisonListWidget extends BaseWidget<{
  plantId: string;
  title: string;
}> {
  static readonly id = 'month-lifetime-inverter-rgm-production-comparison-list';
  static readonly publicFriendly = false;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    return new MonthLifetimeInverterRgmProductionComparisonListWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string }
  ) {
    super(
      injector,
      MonthLifetimeInverterRgmProductionComparisonListWidget.id,
      MonthLifetimeInverterRgmProductionComparisonListWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols,
      data
    );
    this.resolveComponent(async () => {
      const module = await import('../month-lifetime/month-lifetime-inverter-rgm-production-comparison-list.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { plantId: string; title: string }): void {
    this.setData({ plantId: data.plantId, title: data.title });
  }
}
